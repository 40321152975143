const reviews = [
 {
   id: 1,
   name: 'susan smith',
   job: 'web developer',

   text:
     "I'm baby meggings twee health goth +1. Bicycle rights tumeric chartreuse before they sold out chambray pop-up. Shaman humblebrag pickled coloring book salvia hoodie, cold-pressed four dollar toast everyday carry",
 },
 {
   id: 2,
   name: 'anna johnson',
   job: 'web designer',

   text:
     'Helvetica artisan kinfolk thundercats lumbersexual blue bottle. Disrupt glossier gastropub deep v vice franzen hell of brooklyn twee enamel pin fashion axe.photo booth jean shorts artisan narwhal.',
 },
 {
   id: 3,
   name: 'peter jones',
   job: 'intern',

   text:
     'Sriracha literally flexitarian irony, vape marfa unicorn. Glossier tattooed 8-bit, fixie waistcoat offal activated charcoal slow-carb marfa hell of pabst raclette post-ironic jianbing swag.',
 },
 {
   id: 4,
   name: 'bill anderson',
   job: 'the boss',

   text:
     'Edison bulb put a bird on it humblebrag, marfa pok pok heirloom fashion axe cray stumptown venmo actually seitan. VHS farm-to-table schlitz, edison bulb pop-up 3 wolf moon tote bag street art shabby chic. ',
 },
];

export default reviews;